<template>
  <div class="box">
    <homes ref="productImage"></homes>
    <div class="content">
      <div class="search-input">
        <div class="input-icon">
          <img src="@/assets/img/search2.png" alt="" />
          <span class="input-el"></span>
        </div>
        <input
          class="input-search"
          :placeholder=" active !== 2 ? '请输入姓名/身份证查询' : '请输入企业名称/社会信用代码查询'"
          v-model="param.keyword"
          :autofocus="true"
        />
        <div class="input-btn" @click="page">点击查询</div>
      </div>
    </div>

    <!-- 中间样式 -->
    <div class="inner">
      <div class="job-list">
        <div v-if="list.totalPage != 0">
          <ul class="bg-img" v-if="active == 2">
            <li v-for="item in list.rows" :key="item.id">
              <div class="job-primary">
                <div class="info-primary">
                  <div @click="toDetail(item)">
                    <div class="left">
                      <el-row>
                        <el-col :span="8">
                          <img
                            :src="item.logo ? $imgUrl(item.logo) : srcImgUrl"
                            :onerror="$global.srcImgUrl"
                            alt=""
                          />
                        </el-col>
                        <el-col :span="16">
                          <p class="textInfo title">
                            {{ item.name }}
                          </p>
                          <div class="textDetail">
                            {{
                              item.properties ? getOrgType(item.properties) : ""
                            }}
                            ·
                            {{ item.size ? getGm(item.size) : "" }}
                            ·
                            <div class="org-type">
                              {{
                                item.orgType ? getTradeType(item.orgType) : ""
                              }}
                            </div>
                          </div>
                          <!-- <p class="textInfo">{{ geTel(item.phone) }}</p> -->
                        </el-col>
                      </el-row>
                    </div>

                    <div class="right-img">
                      <div class="right right_box">
                        <div class="score">
                          信用分:
                          <span
                            class="score_text"
                            style="padding-left: 40px"
                            v-if="item.grade >= 80"
                            :style="{
                              color: gradeColor[0],
                              borderColor: gradeColor[0],
                            }"
                          >
                            {{ Number(item.grade).toFixed(1) }}
                          </span>
                          <span
                            class="score_text"
                            style="padding-left: 40px"
                            v-if="item.grade < 80 && item.grade >= 60"
                            :style="{
                              color: gradeColor[1],
                              borderColor: gradeColor[1],
                            }"
                          >
                            {{ Number(item.grade).toFixed(1) }}
                          </span>
                          <span
                            class="score_text"
                            style="padding-left: 40px"
                            v-if="item.grade < 60 && item.grade >= 40"
                            :style="{
                              color: gradeColor[2],
                              borderColor: gradeColor[2],
                            }"
                          >
                            {{ Number(item.grade).toFixed(1) }}
                          </span>
                          <span
                            class="score_text"
                            style="padding-left: 40px"
                            v-if="item.grade < 40"
                            :style="{
                              color: gradeColor[3],
                              borderColor: gradeColor[3],
                            }"
                          >
                            {{ Number(item.grade).toFixed(1) }}
                          </span>
                        </div>
                        <div class="city">
                          <i
                            class="iconfont icon-dingxiang"
                            style="margin-right: 20px; font-size: 18px"
                          ></i>
                          <span class="city-city">
                            {{ item.city ? getCityForId(item.city) : '' }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul class="bg-img" v-else>
            <li v-for="item in list.rows" :key="item.id">
              <div class="job-primary">
                <div class="info-primary">
                  <div @click="toDetail(item)">
                    <div class="left">
                      <el-row>
                        <el-col :span="8">
                          <img
                            :src="
                              item.avatar ? $imgUrl(item.avatar) : srcImgUrl1
                            "
                            :onerror="$global.srcImgUrl"
                            alt=""
                          />
                        </el-col>
                        <el-col :span="16">
                          <p class="textInfo title">
                            {{ item.name ? $processName(item.name) : '' }}
                          </p>
                          <p class="textInfo">
                            {{ item.sex === "1" ? "男" : "女" }} |
                            {{ item.age ? item.age : '' }}岁 | {{ item.nation? item.nation : "汉" }}族
                          </p>
                          <p class="textInfo">{{ item.phone ? geTel(item.phone) : '' }}</p>
                        </el-col>
                      </el-row>
                    </div>

                    <div class="right-img">
                      <div class="right right_box">
                        <div class="score">
                          信用分:
                          <span
                            class="score_text"
                            style="padding-left: 40px"
                            v-if="item.grade >= 80"
                            :style="{
                              color: gradeColor[0],
                              borderColor: gradeColor[0],
                            }"
                          >
                            {{ Number(item.grade).toFixed(1) }}
                          </span>
                          <span
                            class="score_text"
                            style="padding-left: 40px"
                            v-if="item.grade < 80 && item.grade >= 60"
                            :style="{
                              color: gradeColor[1],
                              borderColor: gradeColor[1],
                            }"
                          >
                            {{ Number(item.grade).toFixed(1) }}
                          </span>
                          <span
                            class="score_text"
                            style="padding-left: 40px"
                            v-if="item.grade < 60 && item.grade >= 40"
                            :style="{
                              color: gradeColor[2],
                              borderColor: gradeColor[2],
                            }"
                          >
                            {{ Number(item.grade).toFixed(1) }}
                          </span>
                          <span
                            class="score_text"
                            style="padding-left: 40px"
                            v-if="item.grade < 40"
                            :style="{
                              color: gradeColor[3],
                              borderColor: gradeColor[3],
                            }"
                          >
                            {{ Number(item.grade).toFixed(1) }}
                          </span>
                        </div>
                        <div class="city">
                          <i
                            class="iconfont icon-dingxiang"
                            style="margin-right: 20px; font-size: 18px"
                          ></i>
                          <span class="city-city"
                            >
                            <span v-if="item.province">{{ getProvinceForId(item.province) }} ·</span>
                            {{
                              item.city ? getCityForId(item.city) : ''
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div style="text-align: center; margin: 30px 0">
            <el-pagination
              :background="true"
              @current-change="handleCurrentChange"
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <el-empty v-else description="空空如也"></el-empty>
      </div>

      <div class="job-box">
        <div class="sider-list">
          <div class="tabBack">
            <div
              v-for="(item, index) in ellist"
              :key="index"
              :class="item.class"
              @click="changeClass(index)"
            >
              {{ item.label }}
              <div class="tw-ell"></div>
            </div>
          </div>
        </div>

        <div class="sider-list ellent">
          <div class="sider-ellent">
            <div class="ellent-img">
              <p class="sider-text">什么是信用评价？</p>
            </div>

            <div class="eval-ellent">
              信用评价是根据你的基本信息、综合信息以及个人评价来进行评分。评分是展现真实客观的数据，企业或者班组可以通过你的评分来查看你的综合能力。
            </div>
          </div>

          <div class="sider-ellent">
            <div class="ellent-img">
              <p class="sider-text">如何涨分</p>
            </div>

            <div class="eval-ellent">
              信用评价是不同的维度组成，奖惩情况、安全意识、专业能力、工程质量以及其他加减分项，通过信用评价页面上传真实的证明材料来获取更高的评分吧！
            </div>
          </div>
        </div>

        <div class="sider-list sl">
          <div class="h1">上链信息</div>

          <div class="bgc">
            <div class="evaluate-nav">
              <vue-seamless-scroll
                :data="upperList"
                :class-option="optionLeft"
                class="evaluate-warp"
              >
                <ul>
                  <span
                    v-for="(item, ind) in upperList"
                    :key="ind"
                    class="item"
                  >
                    <img
                      src="@/assets/img/lj.png"
                      alt=""
                      style="
                        width: 16px;
                        height: 16px;
                        padding-left: 20px;
                        padding-top: 8px;
                      "
                    />
                    <div
                      style="font-size:13px;font-weight: 400;padding-left:20px;color:#666666"
                    >
                      {{ $processName(item.name) }}信息已
                    </div>
                    <span style="color: #ff8b50; padding-left: 10px;font-size:13px;"
                      >【上链】</span
                    >
                  </span>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <footerss class="footer"></footerss>
  </div>
</template>

<script>
import homes from "../../components/hometitle/hometitle.vue";
import footerss from "../../components/foot/foot.vue";
import { getDict } from "@/api/system/system";
import { getProvincial, getCity, getArea } from "@/api/index/index";
import { newEvaluation } from "@/api/home/home";
import { jobPostType } from "@/api/position/position";
import {
  jobEvaluationSearch,
  jobEvaluationData,
  jobEvaluationOrg,
} from "@/api/evaluation/evaluation";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {
    footerss,
    homes,
    vueSeamlessScroll,
  },
  computed: {
    optionLeft() {
      return {
        step: 0.5,
        direction: 1, // 0向下 1向上 2向左 3向右
        hoverStop: true,
        openTouch: false,
      };
    },
  },
  data() {
    return {
      gradeColor: ["#67CF61", "#3B94F7", "#e6a23c", "#ff0000"],
      srcImgUrl: require("../../assets/home/affiliated-img.png"),
      srcImgUrl1: require("../../assets/home/people.png"),
      list: [],
      dict: [],
      active: 0,
      lastactive: -1,
      upperList: [], //上链滚动
      ellist: [
        { label: "个人榜", class: "tab-active" },
        { label: "班组榜", class: "tabTwo" },
        { label: "劳务榜", class: "tabTwo" },
      ],
      orgType: [],
      jobEdu: [],
      cityList: [],
      showProvinceList: [], //省份列表
      showCityList: [], //城市列表
      jobExp: [],
      tabHover: 1,
      param: {
        pageNo: 1,
        pageSize: 10,
        keyword: "",
        type: "1",
        // grade: "",
      },
      total: 0,
      outerVisible: false,
      job_gm: [],
      orgList: [],
    };
  },
  created() {
    this._getDict();
    this.getOrg();
    this.getUpperList();
    this.showProvinceList = JSON.parse(localStorage.getItem("provincialList"));
    this.showCityList = JSON.parse(localStorage.getItem("cityList"));
    this.cityList = JSON.parse(localStorage.getItem("cityList"));
    if (this.$route.query.value) {
      this.param.keyword = this.$route.query.value;
      this.page();
    } else {
      this.page();
    }
  },
  methods: {
    getCity(code) {
      var obj = this.cityList.find((e) => {
        e.CITY_CODE == code;
      });
      if (obj) {
        return obj.CITY_NAME;
      }
      return "";
    },
    async page() {
      let res = "";
      if (this.active == 2) {
        res = await jobEvaluationOrg(this.param);
      } else {
        res = await jobEvaluationData(this.param);
      }
      if (res.success) {
        this.list = res.data;
        this.total = res.data.totalRows;
      }
    },
    toDetail(item) {
      if (this.active == 2) {
        this.$router.push({
          path: "/labDetail",
          query: {
            id: item.orgCode,
          },
        });
      } else if (this.active == 1) {
        this.$router.push({
          path: "/teamDetail",
          query: {
            id: item.idCard,
          },
        });
      } else {
        this.$router.push({
          path: "/evaluationDetail",
          query: {
            // id: item.idCard,
            id: window.btoa(item.idCard),
          },
        });
      }
    },
    changeClass(index) {
      if (this.active != index) {
        this.lastactive = this.active;
        this.active = index;
        if (this.lastactive != this.active) {
          this.ellist[this.active].class = "tab-active";
          this.ellist[this.lastactive].class = "tabTwo";
        }
        this.param.type =
          this.active == 0
            ? 1
            : this.active == 1
            ? 3
            : this.active == 2
            ? 2
            : 1;
        this.param.pageNo = 1;
        this.page();
      }
    },
    // 添加对应字段
    hasChildren(list) {
      list.forEach((item) => {
        item.name = item.title;
        item.child = item.children;
        item.selected = false;
        if (item.child.length > 0) {
          this.hasChildren(item.child);
        } else {
          item.child = [];
        }
      });
      return list;
    },
    //行业
    async getOrg() {
      let params = {
        code: "trade_type",
      };
      let res = await jobPostType(params);
      this.orgList = this.hasChildren(res.data[0].children);
    },
    // 回显省份
    getProvinceForId(id) {
      return this.showProvinceList.filter((i) => i.PROVINCE_CODE == id)[0]
        .SHORT_NAME;
    },
    // 回显城市
    getCityForId(id) {
      return this.showCityList.filter((i) => i.CITY_CODE == id)[0].SHORT_NAME;
    },
    _getDict() {
      getDict({}).then((res) => {
        if (res.code == 200 && res.message === "请求成功") {
          this.dict = res.data;
          localStorage.setItem("dict", JSON.stringify(res.data));
          this.orgType = this.dict.filter(
            (i) => i.code == "job_org_type"
          )[0].children;
          this.jobEdu = this.dict.filter(
            (i) => i.code == "job_edu"
          )[0].children;
          this.jobExp = this.dict.filter(
            (i) => i.code == "job_exp"
          )[0].children;
          this.job_gm = this.dict.filter((i) => i.code == "job_gm")[0].children;
        }
      });
    },

    getOrgType(id) {
      return this.orgType.filter((i) => i.code == id)[0].name;
    },
    getPostEdu(id) {
      return this.jobEdu.filter((i) => i.code == id)[0].name;
    },
    getPostExp(id) {
      return this.jobExp.filter((i) => i.code == id)[0].name;
    },
    getGm(code) {
      return this.job_gm.filter((i) => i.code == code)[0].name;
    },
    getTradeType(str) {
      let newStr = str.split(",");
      let arr = [];
      if (this.orgList.length > 0) {
        newStr.forEach((i) => {
          let ids = this.getOrgTypeForTreer(this.orgList, i);
          if (ids.length > 0) {
            arr.push(ids);
          }
        });
      }
      let strs = arr.join("，");
      return strs;
    },
    // 根据公司行业id回显  递归找父级id
    getOrgTypeForTreer(list, id) {
      var ids = [];
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          ids.push(list[i].title);
          break;
        } else if (list[i].children && list[i].children.length > 0) {
          ids = this.getOrgTypeForTreer(list[i].children, id);
          if (ids.length > 0) return ids;
        }
      }
      return ids;
    },
    geTel(tel) {
      var reg = /^(\d{3})\d{4}(\d{4})$/;
      return tel ? tel.replace(reg, "$1****$2") : "";
    },
    handleCurrentChange(val) {
      this.param.pageNo = val;
      this.page();
    },

    //上链滚动
    async getUpperList() {
      let res = await newEvaluation();
      this.upperList = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  min-height: 380px;
  width: 100%;
  background: url('~@/assets/img/bgc6.png');
  margin: 0 auto;
  background-size: 100%;
  margin-top: 80px;
  // margin-bottom: 50px;
  background-repeat: no-repeat;
  overflow: hidden;
}

.title_box {
  text-align: center;
  margin-bottom: 50px;
  font-size: 38px;
  color: #029aff;
  .title {
    // margin: 0 40px 0;
    padding-bottom: 10px;
  }
}

.search-input:hover {
  border-style: solid;
  border-color: #029aff;
  border-width: 1px;
  height: 70px;
}
.search-input {
  width: 1000px;
  height: 72px;
  margin: 0 auto;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 330px;

  .input-icon {
    line-height: 72px;
    font-size: 30px;
    opacity: 0.9;
    width: 24px;
    background-color: rgb(244, 251, 255);
    text-align: center;
    padding-left: 35px;
    .input-el {
      height: 32px;
      line-height: 32px;
      opacity: 1;
      background-color: rgb(204, 204, 204);
      border: 1px solid rgb(216, 216, 216);
      margin-left: 30px;
    }
  }

  .input-search {
    width: 790px;
    background-color: rgb(244, 251, 255);
    border: none;
    opacity: 0.9;
    // border-radius: 3px;
    color: #999;
    font-size: 16px;
    padding-left: 70px;
  }

  .input-search:focus {
    outline: none;
  }

  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    border: none;
    color: #999;
  }

  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #999;
  }

  input:-ms-input-placeholder {
    /* IE 10+ */
    color: #999;
  }

  input:-moz-placeholder {
    /* Firefox 18- */
    color: #999;
  }

  .input-btn {
    width: 150px;
    line-height: 72px;
    background-color: #029aff;
    color: #fff;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
  }
}

// 中间样式

.inner {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
// .home-inner {
//   max-width: 1184px;
// }
.job-box {
  // margin-top: 30px;
  // float: right;
  width: 284px;
}

.sider-list {
  background: #fff;
  margin-bottom: 30px;
  .tabBack {
    font-size: 20px;
    text-align: center;
    .tabOne {
      color: #999;
      font-weight: 500;
      text-align: center;
    }
    .tabTwo {
      color: #999;
      font-weight: 500;
      height: 70px;
      line-height: 70px;
      .tw-ell {
        width: 180px;
        margin-left: 50px;
        border-bottom: 1px solid;
        border-image: linear-gradient(90deg, #fff 0%, #a6a6a6 51%, #fff 100%) 2
          2 2 2;
        opacity: 0.6;
      }
    }
    .tab-active {
      height: 70px;
      line-height: 70px;
      color: #fff;
      background-image: url("~@/assets/img/person-ell.png");
      background-repeat: no-repeat;
      background-size: 120%;
      background-position: center;
    }
  }
}

.ellent {
  padding-bottom: 10px;
  padding-top: 10px;
  .sider-ellent {
    background: #f3f9fd;
    margin: 15px;
    .ellent-img {
      width: 160px;
      height: 32px;
      background: url("~@/assets/img/ellTop.png");
      background-repeat: no-repeat;
      background-size: 100%;
      .sider-text {
        color: #fff;
        font-size: 16px;
        height: 30px;
        line-height: 30px;
        font-weight: 400;
        padding-left: 20px;
      }
    }
    .eval-ellent {
      width: 85%;
      padding: 20px;
      color: #999;
      font-size: 16px;
    }
  }
}

.sl {
  .h1 {
    font-size: 16px;
    height: 48px;
    line-height: 48px;
    font-weight: 400;
    padding-left: 30px;
    border-bottom: solid 2px #f4f4f4;
  }

  .bgc {
    background: url("~@/assets/img/ellf.png");
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .evaluate-nav {
    // margin-top: 25px;
    width: 256px;
    height: 230px;
    overflow: hidden;
    position: relative;
    // display: flex;
    .evaluate-warp {
      height: 270px;
      width: 360px;
      margin: 0 auto;
      overflow: hidden;
      ul,
      li {
        padding: 0;
        margin: 0;
      }
      .item {
        height: 30px;
        line-height: 30px;
        display: flex;
        margin: 0 auto;
      }
    }
  }
}

// 左边
ul {
  list-style: none;
}
li {
  list-style: none;
  background: #fff;
}

.job-list {
  width: 980px;
  ul,
  li {
    padding: 0;
    margin: 0;
    background-color: rgb(255, 255, 255, 0.5);
    // background-color: rgba(253, 253, 253, 0.3);
  }
  .bg-img {
    width: 100%;
    background: url("~@/assets/img/right-ell.png");
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 10;
  }
  .job-primary {
    box-sizing: border-box;
    padding: 10px;
    color: #4a4160;
    overflow: hidden;
    margin: 0 30px;
    border-bottom: 1px #e4e4e4 solid;
    position: relative;
    .info-primary {
      padding-top: 20px;
      float: none;

      .left {
        width: 500px;
        float: left;
        img {
          width: 100px;
          height: 100px;
          object-fit: cover;
        }

        .textInfo {
          margin-top: 0;
        }
        .textDetail {
          display: flex;
          flex-wrap: nowrap;
          .org-type {
            margin-left: 5px;
            width: 160px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .title {
          font-weight: 800;
        }
      }

      .right-img {
        // background: url("~@/assets/img/right-ell.png");
        // background-repeat: no-repeat;
        // background-size: 100%;
        .right {
          float: right;
        }

        .right_box {
          width: 200px;
          .score {
            margin-bottom: 50px;
            margin-left: 50px;
            .score_text {
              // border-style: solid;
              border-color: #f22;
              padding-left: 40px;
              color: #f22;
              border-width: 1px;
              padding: 0 10px 0;
              border-radius: 15px 15px 15px 1px;
              font-family: "buer";
            }
          }
          .city {
            font-size: 14px;
            color: #1592ea;
            padding-left: 60px;
            .city-city {
              color: #999999;
            }
          }
        }
      }
    }
  }
}
</style>
